import React from "react";
import { Container } from "react-bootstrap";
const isProduction = process.env.REACT_APP_ENV;

export default function EnvironmentHeader() {
  return (
    <Container
      fluid
      className={`shadow-sm p-3 mb-1 ${
        !isProduction ? "bg-danger text-white" : "bg-body"
      }`}
    >
      <div className="env_header d-flex justify-content-center align-items-center">
        <p className="m-0 p-0 font-weight-bold">
          {!isProduction
            ? "ווילז מערכת ניהול - סביבת בדיקות!"
            : "ווילז - מערכת ניהול"}
        </p>
      </div>
    </Container>
  );
}
