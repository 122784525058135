import React, { useEffect, useState } from "react";
import ClientsTable from "./clientsTable/clientsTable";
import { CreateStationModal } from "./createClientModal/CreateClientModal";
import AreYouSureModal from "../../components/AreYouSureModal/AreYouSureModal";
import { fetchData } from "../../utils/fetchData";
import ToastComponent from "../../components/Toast/Toast";
import { Container } from "react-bootstrap";

const Stations = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [client, setClient] = useState(null);

  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");

  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // FETCHING STATIONS FROM SERVER
  const fetchClients = async (page = 1) => {
    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("userData")).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const params = { page, limit: 10 };
      const requestObject = { request: "clients" };
      const response = await fetchData(requestObject, "GET", token, params);
      setClients(response.clients);
      setTotalPages(response.totalPages);
      setCurrentPage(response.currentPage);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // FUNCTION TO HANDLE WITH REMOVE BUTTON WHEN USER DELETING A STATION
  const handleRemove = async () => {
    try {
      setLoading(true);
      const userData = localStorage.getItem("userData");
      if (!userData) {
        throw new Error("User is not authenticated");
      }

      const token = JSON.parse(userData).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const requestObject = {
        request: `clients/${client.id}`,
      };
      const response = await fetchData(requestObject, "DELETE", token);
      setClient(null);
      setToastType("success");
      setToastMessage(response.message);
      setShowToast(true);
      setIsAreYouSureOpen(false);
      fetchClients();
    } catch (err) {
      console.error(err.message);
      setClient(null);
      setToastType("danger");
      setToastMessage(err.message);
      setShowToast(true);
      setIsAreYouSureOpen(false);
      fetchClients();
    } finally {
      setLoading(false);
    }
  };

  //FUNCTION TO SHOW ARE YOU SURE MODAL
  const handleShowModal = (type) => {
    setModalType(type);
    setIsAreYouSureOpen(true);
  };

  //FUNCTION TO CHANGE THE DEFAULT STATION
  const changeDefaultStation = async () => {
    try {
      const userData = localStorage.getItem("userData");
      if (!userData) {
        throw new Error("User is not authenticated");
      }

      const token = JSON.parse(userData).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const requestObject = {
        request: `clients/${client.id}`,
        isDefault: true,
      };

      await fetchData(requestObject, "PUT", token);
      setClient(null);
      setToastType("success");
      setToastMessage("העמדה המועדפת שונתה בהצלחה");
      setShowToast(true);
      setIsAreYouSureOpen(false);
      fetchClients();
    } catch (err) {
      console.error(err.message);
      setClient(null);
      setToastType("danger");
      setToastMessage("לא ניתן לשנות את העמדה המועדפת");
      setShowToast(true);
      setIsAreYouSureOpen(false);
      fetchClients();
    }
  };

  // FUNCTION TO CLOSE ARE YOU SURE MODAL
  const closeAreYouSureModal = () => {
    setIsAreYouSureOpen(false);
    setClient(null);
  };
  return (
    <Container className="mb-5">
      <div className="pb-3">
        <h2 className="font-weight-bold">לקוחות</h2>
        <p className="p-0 m-0">
          זה המקום להוסיף, לערוך, למחוק ולשנות את לקוחות שיכולות לגשת למערכת.
        </p>
        <p className="p-0 m-0">
          לאחר יצירת לקוח הסעות במערכת, צריך לשייך סדרן שיכול לבצע פועלות עבור
          הלקוח.
        </p>
      </div>
      <ClientsTable
        setIsOpen={setIsOpen}
        handleShowModal={handleShowModal}
        setClient={setClient}
        fetchClients={fetchClients}
        clients={clients}
        totalPages={totalPages}
        loading={loading}
      />
      <CreateStationModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        fetchClients={fetchClients}
        setToastMessage={setToastMessage}
        setShowToast={setShowToast}
        setToastType={setToastType}
        client={client}
        setClient={setClient}
        clients={clients}
      />
      <AreYouSureModal
        isAreYouSureOpen={isAreYouSureOpen}
        closeAreYouSureModal={closeAreYouSureModal}
        handleApprove={modalType === 1 ? handleRemove : changeDefaultStation}
        fetchClients={fetchClients}
        setToastMessage={setToastMessage}
        setShowToast={setShowToast}
        setToastType={setToastType}
        modalTitle={modalType === 1 ? "בטוח למחוק לך?" : "לשנות עמדה מועדפת?"}
        modalMessage={
          modalType === 1
            ? `כן, אני רוצה למחוק את ${client?.name}`
            : `כן, אני רוצה לשנות את ${client?.name} לעמדה המועדפת`
        }
      />
      <ToastComponent
        type={toastType}
        message={toastMessage}
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </Container>
  );
};

export default Stations;
