import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { fetchData } from "../../utils/fetchData";
import LogoUpload from "./logo";

const Settings = () => {
  const [userData] = useState(
    JSON.parse(localStorage.getItem("userData")) || null
  );
  const [toggleableSettings, setToggleableSettings] = useState([]);
  const [colors, setColors] = useState({
    fontColor: "#000000",
    backgroundColor: "#ffffff",
    dotsColor: "#000000",
  });
  const fileInputRef = useRef(null);

  useEffect(() => {
    const getUserSettings = async () => {
      if (userData?.token) {
        try {
          const requestObject = { request: "settings" };
          const response = await fetchData(
            requestObject,
            "GET",
            userData.token
          );
          const filteredSettings = Object.entries(response.settings).filter(
            ([key]) => !["__v", "_id", "id"].includes(key)
          );
          setToggleableSettings(filteredSettings);
          setColors(response.settings.colors || colors); // Initialize colors from response or use default
        } catch (error) {
          console.error("Error fetching settings", error);
        }
      }
    };

    getUserSettings();
  }, [userData]);

  const handleToggleChanged = (toggle) => {
    const updatedSettings = toggleableSettings.map((setting) => {
      if (toggle === setting[0]) {
        return [setting[0], !setting[1]];
      }
      return setting;
    });

    setToggleableSettings(updatedSettings);
  };

  const handleColorChange = (key, value) => {
    setColors((prevColors) => ({
      ...prevColors,
      [key]: value,
    }));
  };

  const sendSettingsToServer = async () => {
    const updatedSettings = toggleableSettings.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    try {
      const requestObject = {
        request: "settings",
        settings: { ...updatedSettings, colors },
      };
      const response = await fetchData(requestObject, "PUT", userData.token);
      console.log(response);
    } catch (error) {
      console.error("Error sending settings to the server", error);
    }
  };

  return (
    <div>
      <h2>Settings</h2>
      <p>Manage your settings here.</p>
      {toggleableSettings.map(([key, value]) => (
        <Form.Group controlId={`formBasicCheckbox-${key}`} key={key}>
          <Form.Check
            type="switch"
            id={key}
            label={
              key.charAt(0).toUpperCase() +
              key
                .slice(1)
                .replace(/([A-Z])/g, " $1")
                .trim()
            }
            checked={value}
            onChange={() => {
              handleToggleChanged(key);
            }}
          />
        </Form.Group>
      ))}

      <LogoUpload />

      <hr />

      <div className="d-flex gap-2">
        <h5 className="lead font-weight-bold pb-2">הגדרות צבעים</h5>
        <div
          className="text-danger pointer"
          onClick={sendSettingsToServer}
          variant="danger"
        >
          איפוס
        </div>
      </div>

      <Form className="d-flex flex-column gap-2">
        <Form.Group className="d-flex gap-2  align-items-center">
          <Form.Control
            type="color"
            className="image_editor__color-input"
            value={colors.fontColor}
            onChange={(e) => handleColorChange("fontColor", e.target.value)}
          />
          <Form.Label>צבע טקסט</Form.Label>
        </Form.Group>
        <Form.Group className="d-flex gap-2 align-items-center">
          <Form.Control
            type="color"
            className="image_editor__color-input"
            value={colors.backgroundColor}
            onChange={(e) =>
              handleColorChange("backgroundColor", e.target.value)
            }
          />
          <Form.Label>צבע רקע</Form.Label>
        </Form.Group>
        <Form.Group className="d-flex gap-2  align-items-center">
          <Form.Control
            type="color"
            className="image_editor__color-input"
            value={colors.dotsColor}
            onChange={(e) => handleColorChange("dotsColor", e.target.value)}
          />
          <Form.Label>צבע מפריד חדשות</Form.Label>
        </Form.Group>
      </Form>
      <hr />

      <div className="gap-2 d-flex pt-3 justify-content-end">
        <Button onClick={sendSettingsToServer}>שמור</Button>
      </div>
    </div>
  );
};

export default Settings;
