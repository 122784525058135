import React, { Fragment, useEffect, useState } from "react";
import { Table, Button, Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../clients.scss";
import "./clientsTable.scss";
import LoaderComponent from "../../../components/LoaderComponent/LoaderComponent";
import { getClientTypeText } from "../../../utils/supportFunctions";
import { fetchData } from "../../../utils/fetchData";

const env = process.env.REACT_APP_ENV || "development";

const ClientsTable = ({
  setIsOpen,
  clients,
  fetchClients,
  totalPages,
  loading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [firstName] = useState(
    JSON.parse(localStorage.getItem("userData")).firstName || ""
  );
  const token = JSON.parse(localStorage.getItem("userData")).token;
  const [activeOrganizers, setActiveOrganizers] = useState({});

  const navigate = useNavigate();
  useEffect(() => {
    fetchClients(currentPage);
    fetchActiveOrganizers();
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchActiveOrganizers = async () => {
    try {
      const response = await fetchData(
        { request: `organizers/active-organizers` },
        "GET",
        token
      );
      const data = await response;
      const flatActiveOrganizers = Object.values(data)
        .flat()
        .reduce((acc, organizerId) => {
          acc[organizerId] = true; // true indicates active
          return acc;
        }, {});

      setActiveOrganizers(flatActiveOrganizers);
    } catch (error) {
      console.error("Error fetching active organizers:", error);
    }
  };

  const handleRowClick = (clientId) => {
    navigate(`/dashboard/clients/${clientId}`);
  };
  return (
    <Fragment>
      {loading ? (
        <LoaderComponent />
      ) : clients?.length > 0 ? (
        <div className="custom-table-container">
          <div className="table-header">
            <div className="d-flex flex-column">
              <span className="lead font-weight-bold">לקוחות רשומים</span>
              <div className="legend d-flex flex-column m-0 p-0">
                <div className="legend-item">
                  <span className="status-circle green-circle me-2"></span>
                  סדרנים שביצעו פעולה במערכת הסדרנים ב3 שעות האחרונות
                </div>
                <div className="legend-item">
                  <span className="status-circle red-circle me-2"></span>
                  סדרנים שלא ביצעו פעולה לאחרונה
                </div>
              </div>
            </div>
            <Button
              className="table-btn"
              variant="primary"
              type="submit"
              onClick={() => setIsOpen(true)}
            >
              יצירת לקוח חדש
            </Button>
          </div>
          <div className="table-container">
            <div className="table-responsive">
              <Table responsive className="table-hover">
                <thead className="thead-light sticky-header">
                  <tr>
                    <th>#</th>
                    <th className="font-weight-medium">שם הלקוח</th>
                    <th className="font-weight-medium">סוג הלקוח</th>
                    <th className="font-weight-medium">מזהה לקוח</th>
                    <th className="font-weight-medium">דוא״ל הנה״ח</th>
                  </tr>
                </thead>
                <tbody>
                  {clients?.map((item, index) => (
                    <tr
                      key={item._id}
                      className="table-row"
                      onClick={() => handleRowClick(item._id)}
                      style={{ cursor: "pointer" }}
                    >
                      {/* {console.log("Active Organizers:", activeOrganizers)} */}
                      {/* {console.log("Current Organizer ID:", item._id)} */}

                      <td>{index + 1}</td>
                      <td>
                        <div className="d-flex gap-2 align-items-center align-self-center">
                          <div
                            className={`d-flex gap-2 align-items-center align-self-center avatar ${
                              activeOrganizers[item._id] ? "online" : "offline"
                            }`}
                          >
                            <label
                              style={{
                                paddingRight: "9px",
                                top: "9px",
                                position: "absolute",
                                fontSize: "13px",
                              }}
                            ></label>
                          </div>
                          <img
                            src={
                              item?.profileImage ||
                              "/uploads/default-profile.png"
                            }
                            alt="Profile"
                            className="profile-image"
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              margin: 0,
                            }}
                          />
                          <div className="d-flex align-items-center">
                            {item.name}
                          </div>
                        </div>
                      </td>
                      <td>{getClientTypeText(item.clientType)}</td>
                      <td>{item.llsNumber}</td>
                      <td>{item.billingEmail}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          {totalPages > 1 && (
            <Pagination>
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          )}
        </div>
      ) : (
        <div className="custom-table-container">
          <div className="mt-3 d-flex flex-column justify-content-center align-items-center">
            <div>
              <p className="lead text-darker font-weight-black text-center mb-0">
                שלום {firstName} 👋
              </p>
              <p className="text-muted text-center mb-4">
                לפני שמתחילים, צריך להוסיף לקוח אחד לפחות באמצעות הכפתור למטה
              </p>
            </div>
            <Button
              className="table-btn mb-2"
              variant="primary"
              type="submit"
              onClick={() => setIsOpen(true)}
            >
              הכפתור למטה
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ClientsTable;
