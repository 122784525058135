import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import "./Sidemenu.scss";
import { MdSpaceDashboard } from "react-icons/md";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { fetchData } from "pdfjs-dist";

const navItems = [
  {
    to: "/dashboard",
    icon: <MdSpaceDashboard />,
    label: "לוח הבקרה",
    end: true,
  },
  {
    to: "/dashboard/clients",
    icon: <BsFillPersonVcardFill />,
    label: "לקוחות",
  },
  {
    to: "/dashboard/trips",
    icon: <BsFillPersonVcardFill />,
    label: "נסיעות",
  },
];

const SideMenu = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogout = (event) => {
    setLoading(true);
    event.preventDefault();

    let dataObj = {
      request: "organizers/logout",
    };

    fetchData(dataObj, "POST")
      .then((response) => {
        console.log("Logout successful");
        localStorage.removeItem("userData");
        setLoading(false);
        navigate("/login");
      })
      .catch((error) => {
        console.log("Logout error", error);
        localStorage.removeItem("userData");
        setLoading(false);
        navigate("/login");
      });
  };

  return (
    <Nav className="d-none d-md-block bg-light sidebar">
      {navItems.map((item, index) => (
        <Nav.Item key={index} className="nav-item">
          <NavLink
            to={item.to}
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            end={item.end}
          >
            <div className="sidebar__item">
              <div className="sidebar__item__icon">{item.icon}</div>
              <span>{item.label}</span>
            </div>
          </NavLink>
        </Nav.Item>
      ))}
      {/* Replace NavLink with Nav.Link for Logout */}
      <Nav.Item className="nav-item">
        <Nav.Link onClick={handleLogout} className="nav-link">
          <div className="sidebar__item">
            <div className="sidebar__item__icon">
              <BsFillPersonVcardFill />
            </div>
            <span>התנתק</span>
          </div>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default SideMenu;
