import React, { useEffect, useState } from "react";
import AreYouSureModal from "../../components/AreYouSureModal/AreYouSureModal";
import { fetchData } from "../../utils/fetchData";
import ToastComponent from "../../components/Toast/Toast";
import TripsTable from "./tripsTable/tripsTable";
import { CreateOrganizerModal } from "./CreateOrganizerModal";

const Organizers = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [organizer, setOrganizer] = useState(null);

  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");

  const [organizers, setOrganizers] = useState([]);
  const [clients, setClients] = useState([]); // State to store clients
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [trips, setTrips] = useState(1);
  const [activeTable, setActiveTable] = useState("active");
  const [userData] = useState(
    JSON.parse(localStorage.getItem("userData")) || null
  );
  const fromDate = "";
  const toDate = "";

  useEffect(() => {
    // fetchActiveScreens();

    fetchStations(); // Fetch clients on component mount
  }, [activeTable]);

  const fetchStations = async (page = 1) => {
    try {
      if (!userData.token) {
        throw new Error("User is not authenticated");
      }
      let dataObj = {
        request: "trips",
        fromDateTime: fromDate,
        toDateTime: toDate,
      };
      const response = await fetchData(dataObj, "POST", userData.token);
      console.log(response.data.trips);
      setTrips(response.data.trips);
      setLoading(false);
    } catch (err) {
      // Handle errors
    } finally {
      // Cleanup or finalize actions
    }
  };

  // FUNCTION TO HANDLE WITH REMOVE BUTTON WHEN USER DELETING AN ORGANIZER
  const handleRemove = async () => {
    try {
      setLoading(true);
      const userData = localStorage.getItem("userData");
      if (!userData) {
        throw new Error("User is not authenticated");
      }

      const token = JSON.parse(userData).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const requestObject = {
        request: `organizers/${organizer._id}`,
      };
      const response = await fetchData(requestObject, "DELETE", token);
      setOrganizer(null);
      setToastType("success");
      setToastMessage(response.message);
      setShowToast(true);
      setIsAreYouSureOpen(false);
    } catch (err) {
      console.error(err.message);
      setOrganizer(null);
      setToastType("danger");
      setToastMessage(err.message);
      setShowToast(true);
      setIsAreYouSureOpen(false);
    } finally {
      setLoading(false);
    }
  };

  // FUNCTION TO SHOW ARE YOU SURE MODAL
  const handleShowModal = (type) => {
    setModalType(type);
    setIsAreYouSureOpen(true);
  };

  // FUNCTION TO CLOSE ARE YOU SURE MODAL
  const closeAreYouSureModal = () => {
    setIsAreYouSureOpen(false);
    setOrganizer(null);
  };

  return (
    <div>
      <h2 className="font-weight-bold">נסיעות</h2>
      <p className="p-0 m-0">ניהול כל הנסיעות, הוספה, הסרה, ועוד.</p>
      <p className="p-0 m-0 pb-3">
        מה זה אומר? כל נסיעה שתוקם במערכת הסדרנים יכולו לראות אותה ולקחת אותה
        במידה ואין נהג.
      </p>

      <TripsTable
        setIsOpen={setIsOpen}
        handleShowModal={handleShowModal}
        setScreen={setOrganizer}
        organizers={organizers}
        totalPages={totalPages}
        loading={loading}
      />

      <CreateOrganizerModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setToastMessage={setToastMessage}
        setShowToast={setShowToast}
        setToastType={setToastType}
        organizer={organizer}
        setOrganizer={setOrganizer}
        clients={clients} // Pass clients to the modal
      />

      <AreYouSureModal
        isAreYouSureOpen={isAreYouSureOpen}
        closeAreYouSureModal={closeAreYouSureModal}
        handleApprove={handleRemove}
        setToastMessage={setToastMessage}
        setShowToast={setShowToast}
        setToastType={setToastType}
        modalTitle={"בטוח למחוק לך"}
        modalMessage={`כן, אני רוצה למחוק את ${organizer?.firstName} ${organizer?.lastName}`}
      />

      <ToastComponent
        type={toastType}
        message={toastMessage}
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </div>
  );
};

export default Organizers;
