import React, { useState, useRef } from "react";
import { Form, Button } from "react-bootstrap";

const ProfileImageUpload = () => {
  const [profileImage, setProfileImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setProfileImage(null);
    fileInputRef.current.value = null;
  };

  return (
    <div style={{ textAlign: "center", margin: "20px 0" }}>
      <div style={{ position: "relative", display: "inline-block" }}>
        {profileImage ? (
          <img
            src={profileImage}
            alt="Profile"
            style={{ width: "150px", height: "150px", borderRadius: "50%" }}
          />
        ) : (
          <div
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              backgroundColor: "#ddd",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
              color: "#aaa",
            }}
          >
            <span>העלאת לוגו</span>
          </div>
        )}
        <Form.Control
          type="file"
          onChange={handleImageChange}
          ref={fileInputRef}
          accept="image/png, image/gif, image/jpeg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: "pointer",
            borderRadius: "50%",
          }}
        />
        {profileImage && (
          <Button
            variant="danger"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              transform: "translate(50%, -50%)",
              borderRadius: "50%",
            }}
            onClick={removeImage}
          >
            &times;
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProfileImageUpload;
