import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdSpaceDashboard, MdComputer } from "react-icons/md";
import "./mobileTopMenu.scss";
import Logo from "../../assets/icons/logo.svg";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { fetchData } from "pdfjs-dist";

const navItems = [
  {
    to: "/dashboard",
    icon: <MdSpaceDashboard />,
    label: "לוח הבקרה",
    end: true,
  },
  {
    to: "/dashboard/clients",
    icon: <MdComputer />,
    label: "לקוחות",
  },
  {
    to: "/dashboard/trips",
    icon: <BsFillPersonVcardFill />,
    label: "נסיעות",
  },
];

const MobileTopMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogout = (event) => {
    setLoading(true);
    event.preventDefault();

    let dataObj = {
      request: "organizers/logout",
    };

    fetchData(dataObj, "POST")
      .then((response) => {
        console.log("Logout successful");
        localStorage.removeItem("userData");
        setLoading(false);
        navigate("/login");
      })
      .catch((error) => {
        console.log("Logout error", error);
        localStorage.removeItem("userData");
        setLoading(false);
        navigate("/login");
      });
  };

  return (
    <Navbar
      variant="dark"
      expand="lg"
      className="d-md-none navbar"
      sticky="top"
    >
      <Navbar.Brand as={Link} to="/dashboard">
        <img
          className="img-thumbnail mx-auto d-block mb-2 login__form__logo"
          src={Logo}
          alt="logo"
          style={{ width: "150px" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded(!expanded)}
        className="custom-toggler"
      />
      <Navbar.Collapse id="basic-navbar-nav" in={expanded}>
        <Nav className="mr-auto">
          {navItems.map((item, index) => (
            <Nav.Link
              key={index}
              as={Link}
              to={item.to}
              onClick={() => setExpanded(false)}
              className={location.pathname === item.to ? "active" : ""}
            >
              {item.icon} {item.label}
            </Nav.Link>
          ))}
          {/* Add logout option */}
          <Nav.Link onClick={handleLogout} className="logout-link">
            התנתק
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MobileTopMenu;
